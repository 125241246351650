import BuddyInfo from './components/BuddyInfo';

import useSound from 'use-sound';
import startup from './windows-xp-startup.mp3';

import './App.css';
import 'xp.css';

function App() {
  const [play] = useSound(startup, {
		interrupt: true,
	});

  return (
    <div className="App">
      <div className="desktop" onClick={() => play()}></div>
      <BuddyInfo />
    </div>
  );
}

export default App;
