import useSound from 'use-sound';
import sounds from '../sounds.mp3';

export default function BuddyInfo() {
	const soundMap = {
		register: [2300, 2000],
		doorOpen: [5000, 1500],
		doorClose: [6800, 1000],
		msgRcv: [8600, 1000],
		msgSnd: [10300, 1000],
		moo: [12200, 1000],
		bell: [14000, 1000],
		whip: [15600, 500],
		ring: [16700, 2200],
		firstMsg: [19000, 1000],
		connect: [20800, 1000],
		disconnect: [22200, 1000],
		error: [23700, 1000],
	};

	const [play] = useSound(sounds, {
		interrupt: true,
		sprite: soundMap,
	});

	return (
		<div className="buddy-info window">
			<div className="title-bar">
				<span className="title-bar-text">
					<span>Buddy Info: SmarterChild</span>
				</span>
				<div className="title-bar-controls">
					<button aria-label="Minimize" onClick={() => play({id: 'msgRcv'})}></button>
					<button aria-label="Maximize" onClick={() => play({id: 'msgSnd'})}></button>
					<button aria-label="Help" onClick={() => play({id: 'doorOpen'})}></button>
					<button aria-label="Close" onClick={() => play({id: 'doorClose'})}></button>
				</div>
			</div>
			<div className="window-body">
				<div className="get-info">
					<p>Enter the screen name of the user you wish to get info about:</p>
					<input type="text" value="SmarterChild" name="get-screename" className="get-screename" />
					<button onClick={() => play({id: 'firstMsg'})}>OK</button>
				</div>
				<hr />
				<div className="details">
					<p><strong>Warning Level:</strong> 0%</p>
					<p><strong>Online time:</strong> 4 minutes</p>
				</div>
				<hr />
				<div className="profile-container">
					<p><strong>Personal Profile:</strong></p>
					<div class="profile">
						Hi, I am a bot.
					</div>
				</div>
			</div>
		</div>
	);
}
